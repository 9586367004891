<template>
  <div>
    <b-card>
      <b-row>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-input-group>
              <b-form-datepicker
                id="sdate"
                v-model="filterData.sdate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="clearSdate"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-input-group>
              <b-form-datepicker
                id="edate"
                v-model="filterData.edate"
                v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                locale="tr"
                start-weekday="1"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="clearEdate"
                >
                  <FeatherIcon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            @click="getReport"
          >
            <FeatherIcon icon="PieChartIcon" />
            Raporu Görüntüle
          </b-button>
        </b-col>
      </b-row>

    </b-card>
    <pre-loading v-if="loading" />
    <template v-if="report.data.length > 0">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Durumlar</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row v-if="report.data.length > 0">
            <b-col
              v-for="(detail,key) in report.data"
              :key="key"
            >
              <div class="border p-2 text-center rounded bg-light mb-2">
                <div class="font-medium-3 font-weight-light text-nowrap">
                  {{ detail.title }}
                </div>
                <div class="font-weight-bolder font-large-1 text-primary">
                  {{ detail.count | toNumber }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-alert
            v-else
            show
          >
            <div class="alert-body text-center">
              Servis başvuru verisi bulunmamaktadır.
            </div>
          </b-alert>
        </b-card-body>
      </b-card>

    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BAlert, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'UnreachableRequests',
  components: {
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BAlert,
    PreLoading,
  },
  computed: {
    loading() {
      return this.$store.getters['CustomerKvkkReport/getLoading']
    },

    report() {
      return this.$store.getters['CustomerKvkkReport/getReport']
    },
    filterData() {
      return this.$store.getters['CustomerKvkkReport/getFilterData']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('CustomerKvkkReport/report', this.filterData)
    },
    clearSdate() {
      this.filterData.sdate = null
    },
    clearEdate() {
      this.filterData.edate = null
    },
  },

}
</script>
